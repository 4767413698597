import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import FilterListIcon from "@material-ui/icons/FilterList";

import TableCells from "./TableCells";
import Buttons from "../Form/Button/Button";
import SelectField from "../Form/SelectField/SelectField";
import Filter from "./Filter";
import SearchBox from "../Form/SearchBox/SearchBox";
import Search from "./Search";

import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import TooltipLabel from "../Form/Tooltip/Tooltip";
import EmptyCard from "../EmptyCard/EmptyCard";
import Loader from "../Loader/Loader";

import Datefield from "../Form/Datefield/Datefield";
import SouthRoundedIcon from "@mui/icons-material/SouthRounded";
import "./Table.css";

import FilterAltIcon from "@mui/icons-material/FilterAlt";

import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import Button from "@mui/material/Button";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    CheckboxDelete,
    DeleteText,
    setSearchTerm,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const [isActive, setActive] = useState("false");
  const handleToggle = () => {
    setActive(!isActive);
  };

  return (
    <TableHead>
      <TableRow>
        {CheckboxDelete && (
          <TableCell padding="checkbox" style={{ paddingRight: "0" }}>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
              style={{ padding: "0", position: "relative", left: "-1px" }}
            />
          </TableCell>
        )}
        {numSelected !== 0 ? (
          <TableCell style={{ display: "flex", justifyContent: "flex-start" }}>
            <Button
              onClick={props.TableHederButtonFn}
              variant="outlined"
              sx={{
                padding: "4px 8px",
                color: "#001233",
                fontSize: "14px",
                fontWeight: 600,
                borderColor: "#E8EAED",
                borderRadius: "8px",
                "&:hover": {
                  color: "#001233",
                  borderColor: "#E8EAED",
                },
              }}
            >
              {props.TableHederButtonText}
            </Button>
          </TableCell>
        ) : (
          typeof headCells !== "undefined" &&
          headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              // align={headCell.numeric ? 'left' : 'right'}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{
                minWidth: headCell.width,
                width: headCell.width,
                textAlign: headCell.textAlign,
              }}
            >
              <TableSortLabel
                onClick={(e) => {
                  if (headCell.headerOnClick) {
                    headCell.headerOnClick();
                    handleToggle();
                  }
                }}
              >
                {headCell.label}{" "}
                {headCell.sort && (
                  <div className={isActive ? "yes" : headCell.sort}>
                    <SouthRoundedIcon className="SortIcon" />
                  </div>
                )}
              </TableSortLabel>
            </TableCell>
          ))
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const { innerTitle } = props;
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {innerTitle}
        </Typography>
      )}

      {numSelected > 0 ? (
        <IconButton
          aria-label="delete"
          className="tableDeleteModalButton"
          onClick={props.DeleteButtonOnclick}
        >
          <DeleteForeverRoundedIcon />
          {props.DeleteText}
        </IconButton>
      ) : (
        <Tooltip title="Filter list" style={{ display: "none" }}>
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable(props) {
  const {
    rows,
    col,
    perPageItems,
    handleOpenDialog,
    btnHandleClick,
    iconButtonHandler,
    emptyTableNote = "No items available",
    isDataLoading = false,
    emptyTableTitle = "No Data",
    dateFilter,
    handleDateFilterChange,
    handleCheckItem,
    selectedCheckList,
    setSelectedToBlank,
    onClickEmptyCard,
    btnLabel,
    showButton,
    btnLink,
    pageNumberFromOutSide,
  } = props;

  console.log("rows from table", rows);

  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState(
    selectedCheckList?.length ? selectedCheckList : []
  );
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);

  const RowsPerPage = props.RowsPerPage ? props.RowsPerPage : 5;
  const [rowsPerPage, setRowsPerPage] = React.useState(RowsPerPage);

  useEffect(() => {
    if (pageNumberFromOutSide) {
      setPage(0);
    }
  }, [pageNumberFromOutSide]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    if (handleCheckItem) {
      handleCheckItem(selected);
    }
    setSelectedToBlank && setSelected([]);
  }, [selected, setSelectedToBlank]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      let newSelecteds;
      if (isCell()) {
        newSelecteds = rows.map((n) => n.cell[0][props.customCellObjectId]);
      } else {
        newSelecteds = rows.map((n) => n.item);
      }
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isCell = () => {
    return (
      typeof props.customCellObjectId !== "undefined" &&
      props.customCellObjectId !== ""
    );
  };

  const handleClick = (event, item) => {
    let selectedIndex;

    if (isCell()) {
      selectedIndex = selected?.findIndex(
        (innerItem) => innerItem === item[0][props.customCellObjectId]
      );
    } else {
      selectedIndex = selected.findIndex(
        (innerItem) => innerItem?._id === item?._id
      );
    }
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = isCell()
        ? newSelected.concat(selected, item[0][props.customCellObjectId])
        : newSelected.concat(selected, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);

    if (props.handleRowClick) {
      props.handleRowClick(event, item);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense((event.target.checked = true));
  };

  const isSelected = (item) => {
    if (isCell()) {
      if (item && item.length > 0) {
        return (
          selected?.findIndex(
            (innerItem) => item[0][props.customCellObjectId] === innerItem
          ) !== -1
        );
      }
    }
    return (
      selected?.findIndex((innerItem) => item?._id === innerItem?._id) !== -1
    );
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows?.length - page * rowsPerPage);

  // Define a function to reset the page to 0
  const resetPageToZero = () => {
    setPage(0);
  };
  return (
    <div className="Table">
      <div className="TableHeaderMainContainer">
        {/* === Main Header == */}
        {props.tableHeader && (
          <div className="TableHeader">
            <h1 className="TableTitle">{props.title}</h1>
            <div className="TableAddButtonContainer">
              {props.HeaderSelectField && (
                <SelectField
                  selectedOption={props.HeaderSelectSelectedOption}
                  options={props.HeaderSelectFieldOptions}
                  handleChange={props.HeaderSelecthandleChanges}
                />
              )}

              {props.AddButtonOutLine && (
                <Buttons
                  label={props.OutLineButtonLabel}
                  icon={props.OutLineButtonIcon}
                  buttonColor="#fff"
                  border="#DBDDE0 1px solid"
                  color="#001233"
                  height="32px"
                  fontSize="14px"
                  link={props.linkButtonOutline}
                  onClick={
                    props?.onClickOutlineButton
                      ? props.onClickOutlineButton
                      : undefined
                  }
                  className="cy_AddButton"
                />
              )}

              {props.AddButtonoutline && (
                <Buttons
                  icon={props.InnderButtonIcon}
                  label={props.addLabelInner}
                  buttonColor={props.InnerButtonColor}
                  color={props.InnerButtonTextColor}
                  border={props.InnerButtonBorder}
                  height="32px"
                  fontSize="14px"
                  link={props.linkInnerButton}
                  onClick={
                    props?.onClickInnerButton
                      ? props.onClickInnerButton
                      : undefined
                  }
                  // add clas name here
                  className="cy_AddButton"
                />
              )}

              {props.SecondInnerButton && (
                <Buttons
                  icon={props.SecondInnerButtonIcon}
                  label={props.SecondInnerButtonLabel}
                  buttonColor={props.SecondInnerButtonColor}
                  color={props.SecondInnerButtonTextColor}
                  border={props.SecondInnerButtonBorder}
                  height="32px"
                  fontSize="14px"
                  link={props.SecondInnerButtonButton}
                  onClick={
                    props?.onClickSecondInnerButton
                      ? props.onClickSecondInnerButton
                      : undefined
                  }
                  // add clas name here
                  className="cy_AddButtonSecond"
                />
              )}

              {props.SearchBoxTop && (
                <div className="Table_S_B">
                  <div>
                    {props.showSearchCount && props.searchBoxText && (
                      <div className="TableSearchField">
                        <span className="RowNumber">
                          {rows?.length > 0
                            ? `${rows.length} results found`
                            : "No matching records found"}{" "}
                        </span>
                      </div>
                    )}
                  </div>

                  {props.showFoundResult && props.isSearchResult && (
                    <div className="SearchResult">
                      {props.foundResult} results found
                    </div>
                  )}

                  <Search
                    SearchPlaceholder={
                      props.searchBoxPlaceholder
                        ? props.searchBoxPlaceholder
                        : "Search here"
                    }
                    SearchValue={props.searchBoxText}
                    SearchOnChange={props.handleSearchText}
                    SearchOnclick={props.SearchOnclick}
                    SearchOnMouseDown={props.SearchOnMouseDown}
                    setResetSearchTerm={props.setResetSearchTerm}
                    resetsearchTerm={props.resetsearchTerm}
                    setSearchTerm={props.setSearchTerm}
                    handleSearch={props.handleSearch}
                  />
                </div>
              )}

              {props.FilterButtonTop && (
                <div className="FilterButtonDrawer">
                  <Buttons
                    icon={<FilterAltIcon />}
                    label="Filter"
                    buttonColor="#fff"
                    border="#C6C6D0 0px solid"
                    color="#5C677D"
                    height="20px"
                    fontSize="14px"
                    filterCountStatus={props.filterCountStatus}
                    filterCountNumber={props.filterCountNumber}
                    onClick={props.onClickFilterButton}
                  />
                </div>
              )}

              {props.ExportButtonInner && (
                <Buttons
                  icon={<FileUploadOutlinedIcon />}
                  label="EXPORT"
                  buttonColor="#fff"
                  border="#C6C6D0 0px solid"
                  color="#5C677D"
                  height="20px"
                  fontSize="14px"
                  onClick={props.OnclickExport}
                />
              )}

              {props.AddButton && (
                <Buttons
                  icon={props.icon}
                  label={props.addLabel}
                  buttonColor="#0450E1"
                  color="#fff"
                  height="32px"
                  fontSize="14px"
                  link={props.link}
                  onClick={props?.onClick ? props.onClick : undefined}
                  className="cy_AddButton"
                />
              )}
            </div>
          </div>
        )}
        {/* === Main Header End == */}

        {/* === Inner Header Start == */}
        {props.innerHeader && (
          <div className="TableHeaderContainer">
            <div className="TableInnerHeader">
              <div className="TableInnerHeaderLeft">
                <h3>{props.innerTitle}</h3>

                {props.DateField && (
                  <div className="TableDateField">
                    <div className="DateChangeArrowArrowContainer">
                      <div className="DateChangeArrow">
                        <Button
                          className="ArrowLeft arrow"
                          onClick={props.goToPreviousDate}
                        >
                          <ArrowBackIosNewRoundedIcon />
                        </Button>
                      </div>
                      <div className="DateChangeArrow">
                        <Button
                          className="ArrowRight arrow"
                          onClick={props.goToNextDate}
                        >
                          <ArrowForwardIosRoundedIcon />
                        </Button>
                      </div>
                    </div>
                    <Datefield
                      value={dateFilter}
                      handleChange={handleDateFilterChange}
                      dateFormat={props?.filterDateFormat}
                    />
                  </div>
                )}
              </div>

              <div className="TableInnerHeaderRight">
                <div className="Table_S_B">
                  <div>
                    {props.showSearchCount && props.searchBoxText && (
                      <div className="TableSearchField">
                        <span className="RowNumber">
                          {rows?.length > 0
                            ? `${rows.length} results found`
                            : "No matching records found"}{" "}
                        </span>
                      </div>
                    )}
                  </div>

                  {props.showFoundResult && props.isSearchResult && (
                    <div className="SearchResult">
                      {props.foundResult} results found
                    </div>
                  )}

                  {props.showSearchBox && (
                    <Search
                      SearchPlaceholder={
                        props.searchBoxPlaceholder
                          ? props.searchBoxPlaceholder
                          : "Search here"
                      }
                      SearchValue={props.searchBoxText}
                      SearchOnChange={props.handleSearchText}
                      SearchOnclick={props.SearchOnclick}
                      SearchOnMouseDown={props.SearchOnMouseDown}
                      setResetSearchTerm={props.setResetSearchTerm}
                      resetsearchTerm={props.resetsearchTerm}
                      setSearchTerm={props.setSearchTerm}
                      handleSearch={props.handleSearch}
                    />
                  )}
                </div>

                {props.FilterButtonDrawer && (
                  <div className="FilterButtonDrawer">
                    <Buttons
                      icon={<FilterAltIcon />}
                      label="Filter"
                      buttonColor="#fff"
                      border="#C6C6D0 0px solid"
                      color="#5C677D"
                      height="20px"
                      fontSize="14px"
                      filterCountStatus={props.filterCountStatus}
                      filterCountNumber={props.filterCountNumber}
                      onClick={props.onClickFilterButton}
                    />
                  </div>
                )}

                {props.AddButtonInner && (
                  <Buttons
                    icon={props.InnderButtonIcon}
                    label={props.addLabelInner}
                    buttonColor={props.InnerButtonColor}
                    color={props.InnerButtonTextColor}
                    border={props.InnerButtonBorder}
                    height="32px"
                    fontSize="14px"
                    link={props.linkInnerButton}
                    onClick={
                      props?.onClickInnerButton
                        ? props.onClickInnerButton
                        : undefined
                    }
                    // add clas name here
                    className="cy_AddButton"
                  />
                )}

                {props.SelectField && (
                  <SelectField
                    selectedOption={props.selectedOption}
                    options={props.options}
                    handleChange={props.handleChanges}
                  />
                )}

                {props.FilterButton && <Filter />}
              </div>
            </div>

            {props.CheckboxDeleteModal && (
              <EnhancedTableToolbar
                numSelected={selected?.length}
                DeleteText={props.DeleteText}
                DeleteButtonOnclick={props.DeleteButtonOnclick}
              />
            )}
          </div>
        )}
        {/* === Inner Header End == */}
      </div>

      <div className="TableContainer DrawerTable">
        <Paper className={classes.paper}>
          <TableContainer>
            {rows?.length > 0 && !isDataLoading ? (
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  headCells={props.headCells}
                  CheckboxDelete={props.CheckboxDelete}
                  TableHederButtonText={props.TableHederButtonText}
                  TableHederButtonFn={props.TableHederButtonFn}
                  classes={classes}
                  numSelected={selected?.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={
                    props.selectAllHander
                      ? props.selectAllHander
                      : handleSelectAllClick
                  }
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      let isItemSelected = isSelected(row.item);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      isItemSelected = isCell() && isSelected(row.cell);

                      //here unique key is set to the key prop of the TableRow component
                      let rowUniqueKey = isCell()
                        ? row.cell[0][props.customCellObjectId]
                        : row?.item?._id || row.cell;

                      return (
                        <TableRow
                          // hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={rowUniqueKey}
                          selected={row.cell[0].Radio ? false : isItemSelected}
                          sx={{ cursor: "pointer" }}
                          //onClick={(event) => handleClick}
                          // onClick={(event) => handleClick(event, row.cell)}
                        >
                          {/* {props.CheckboxDelete && (
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  'aria-labelledby': labelId,

                                }}
                              />
                            </TableCell>
                          )} */}
                          <TableCells
                            cell={row.cell}
                            action={row?.action}
                            handleOpenDialog={handleOpenDialog}
                            btnHandleClick={btnHandleClick}
                            iconButtonHandler={iconButtonHandler}
                            handleClick={handleClick}
                            isItemSelected={isItemSelected}
                          />
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            ) : isDataLoading ? (
              // <div style={{height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Loader
                height="100px"
                width="100px"
                borderWidth="10px"
                isFullWidth={true}
              />
            ) : (
              // </div>
              <EmptyCard
                description={emptyTableNote}
                title={emptyTableTitle}
                showButton={showButton}
                btnLabel={btnLabel}
                onClick={onClickEmptyCard}
                btnLink={btnLink}
              />
            )}
          </TableContainer>
          {props.footer && (
            <div className="tablePage TableFooter">
              <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20, 25]}
                component="div"
                count={rows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                setPage={setPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={
                  <div className="PaginationResultLabel">Results</div>
                }
              />
            </div>
          )}
        </Paper>
        {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
      </div>
    </div>
  );
}
