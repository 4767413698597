import React, { useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import PortalSwitcher from "../../components/PortalSwitcher/PortalSwitcher";
import { useLocation } from "react-router-dom";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineSetting } from "react-icons/ai";
import { RiHome4Fill } from "react-icons/ri";
import { RxPerson } from "react-icons/rx";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import PermMediaOutlinedIcon from "@mui/icons-material/PermMediaOutlined";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import CustomMenuItem from "./CustomMenuItem";
import "../../assets/css/SideBarMenu.css";
import { useGetSchoolQuery } from "../../redux/features/schools/schoolApi";
import useProtected from "../../hooks/useProtected";
import { useEffect } from "react";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import {
  isTheActionPermitted,
  isTheSectionPermitted,
} from "../../hooks/HelperFunctionsPermission";
import StoreIcon from "@material-ui/icons/Store";

function SideBarMenu(props) {
  // getting data from env file
  const dataFromEnvFile = process.env.REACT_APP_ENV;

  // const schoolInformation = useSelector(state => state.auth.user);
  const { schoolId } = useSelector((state) => state.auth.user);

  const { data, isError, isLoading, error } = useGetSchoolQuery(schoolId);

  useProtected(error);

  const pathname = useLocation().pathname;
  const qafysLogo =
    "https://web-assets.keeperedu.com/assets/logos/keeperedu-logos/keeper-education-management-logo-bg-wht.png";
  const dsLogoUrl = "https://portal.deenschool.io/";
  const dispatch = useDispatch();
  const [selectedMenu, setSelectedMenu] = useState("home");
  const [schoolInfo, setSchoolInfo] = useState({});
  const [schoolLogoIcon, setSchoolLogoIcon] = useState("");
  const [schoolLogoIconName, setSchoolLogoIconName] = useState("");
  const [schoolLogo, setSchoolLogo] = useState("");
  const [schoolLogoName, setSchoolLogoName] = useState("");

  // useEffect(() => {
  //   setSchoolInfo(schoolInformation);
  // }, [schoolInformation])

  useEffect(() => {
    if (!isLoading && data && !error) {
      setSchoolInfo(data?.body?.data);
    }
  }, [data, error, isLoading]);

  useEffect(() => {
    setSchoolLogoIcon(schoolInfo?.schoolIcon || "");
    setSchoolLogoIconName(schoolInfo?.schoolIconName || "");
    setSchoolLogo(schoolInfo?.schoolLogo || "");
    setSchoolLogoName(schoolInfo?.schoolLogoName || "");
  }, [schoolInfo]);

  // const isTheSectionPermitted = (sectionName) => {
  //   let found = false
  //   rolesWithPermissions?.map(roles => {
  //     roles?.permissions?.map(singlePermission => {
  //       if (singlePermission?.name == sectionName) {
  //         found = true
  //       }
  //     })
  //   })
  //   return found
  // }

  return (
    <div className="SideBarContainer">
      <div className="SideBarInnerContainer">
        <div className="BrandMainLogo">
          {dsLogoUrl?.length > 0 && (
            <a
              className="Branding"
              // href={dsLogoUrl?.length > 0 ? dsLogoUrl : '#'}
              href="/"
            >
              <img
                src={schoolLogo !== "" ? schoolLogo : qafysLogo}
                style={{ height: "40px", objectFit: "contain" }}
                alt="KeeperEdu owner portal logo"
              />
            </a>
          )}
        </div>

        <div className="SideBarMenu">
          <Sidebar collapsed={props.collapsed}>
            <PortalSwitcher />
            {/* ----------------------  ParentStudentRoutes portal ------------------------ */}
            {props.ParentStudentRoutes && (
              <Menu>
                {/* <CustomMenuItem className={`${pathname === '/listing-example' ? 'LinkActive' : 'link'}`} href="/listing-example"> <MenuBookRoundedIcon />  <span className="MenuPer">Table Example</span> <div className="MenuTooltip"><a className="MenuTooltipLink" href="/listing-example">Table Example</a></div></CustomMenuItem> */}
                <CustomMenuItem
                  className={`${
                    pathname === "/parent-portal" ? "LinkActive" : "link"
                  }`}
                  href="/parent-portal"
                >
                  <RiHome4Fill /> <span className="MenuPer">Home</span>{" "}
                  <div className="MenuTooltip">
                    <a className="MenuTooltipLink" href="/users">
                      Users
                    </a>
                  </div>
                </CustomMenuItem>
              </Menu>
            )}
            {/* ---------------------- School portal ------------------------ */}
            {props.SchoolRoute && (
              <Menu>
                <CustomMenuItem
                  className={`${pathname === "/" ? "LinkActive" : "link"}`}
                  href="/"
                >
                  <StoreIcon /> <span className="MenuPer">Orders</span>{" "}
                  <div className="MenuTooltip">
                    {" "}
                    <a className="MenuTooltipLink" href="/">
                      {" "}
                      Orders
                    </a>
                  </div>
                </CustomMenuItem>

                {/* users */}

                <SubMenu
                  defaultOpen={
                    pathname.includes("/users") ||
                    pathname.includes("/groups") ||
                    pathname.includes("/invite") ||
                    pathname.includes("/group-details")
                  }
                  className="MenuButtonPr"
                  label={
                    <div className="Submenue">
                      <RxPerson /> <span className="MenuPer">Users</span>
                    </div>
                  }
                >
                  <div className="submenutitle">Users</div>
                  {isTheActionPermitted("manageUsers") && (
                    <CustomMenuItem
                      className={`${
                        pathname.includes("/users") ? "LinkActive" : "link"
                      }`}
                      href="/users"
                    >
                      <FiberManualRecordRoundedIcon className="DotIcon" /> User
                      List
                    </CustomMenuItem>
                  )}
                  {isTheActionPermitted("manageUserGroups") && (
                    <CustomMenuItem
                      className={`${
                        pathname.includes("/group-details") ||
                        pathname.includes("/groups")
                          ? "LinkActive"
                          : "link"
                      }`}
                      href="/groups"
                    >
                      <FiberManualRecordRoundedIcon className="DotIcon" /> User
                      Groups
                    </CustomMenuItem>
                  )}

                  {isTheActionPermitted("inviteUsers") ? (
                    <CustomMenuItem
                      className={`${
                        pathname.includes("/invite") ? "LinkActive" : "link"
                      }`}
                      href="/invite-users"
                    >
                      <FiberManualRecordRoundedIcon className="DotIcon" />
                      Invite Users
                    </CustomMenuItem>
                  ) : (
                    ""
                  )}

                  {isTheActionPermitted("manageRolesPermissions") ? (
                    <CustomMenuItem
                      className={`${
                        pathname.includes("/roles-permissions")
                          ? "LinkActive"
                          : "link"
                      }`}
                      href="/roles-permissions"
                    >
                      <FiberManualRecordRoundedIcon className="DotIcon" />
                      Roles & Permissions
                    </CustomMenuItem>
                  ) : (
                    ""
                  )}

                  {/* <CustomMenuItem className={`${pathname.includes('/roles-permissions') ? 'LinkActive' : 'link'}`}
                    href="/roles-permissions"><FiberManualRecordRoundedIcon className="DotIcon" /> Roles & Permissions </CustomMenuItem> */}
                </SubMenu>

                <CustomMenuItem
                  className={`${
                    pathname === "/media-library-table-view"
                      ? "LinkActive"
                      : "link"
                  }`}
                  href="/media-library-table-view"
                >
                  <PermMediaOutlinedIcon />{" "}
                  <span className="MenuPer">Media</span>{" "}
                  <div className="MenuTooltip">
                    <a
                      className="MenuTooltipLink"
                      href="/media-library-table-view"
                    >
                      Media
                    </a>
                  </div>
                </CustomMenuItem>

                {/* <CustomMenuItem
                  className={`${
                    pathname === "/profile" ? "LinkActive" : "link"
                  }`}
                  href="/profile"
                >
                  <AccountCircleOutlinedIcon />{" "}
                  <span className="MenuPer">Profile</span>{" "}
                  <div className="MenuTooltip">
                    <a className="MenuTooltipLink" href="/settings">
                      Profile
                    </a>
                  </div>
                </CustomMenuItem> */}
              </Menu>
            )}
          </Sidebar>
        </div>
        <div className="poweredByContainer">
          <p>Powered by</p>
          {/* <img src={schoolLogo != "" ? schoolLogo : qafysLogo} style={{ height: "40px", objectFit: 'contain' }} /> */}
          <img
            src="https://web-assets.keeperedu.com/assets/logos/keeperedu-logos/keeper-education-management-logo-bg-wht.png"
            alt="logo"
          />
        </div>
      </div>
    </div>
  );
}

export default SideBarMenu;
