import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import CheckBox from "../../components/Form/CheckBox/CheckBox";
import Datefield from "../../components/Form/Datefield/Datefield";

import "../../assets/css/filter.css";
import dayjs from "dayjs";
import { toTitleCase } from "../../utils/helpers";
import { useGetUserTypesQuery } from "../../redux/features/user_types/userTypesApi";
import useProtected from "../../hooks/useProtected";

const Filter = (props) => {
  const { userTypes, setUserTypes, roles } = props;

  const changeUserTypeHandler = (e, value) => {
    let newUserTypes = [...userTypes];
    if (e.target.checked) {
      if (!userTypes.includes(value)) {
        newUserTypes.push(value);
        setUserTypes(newUserTypes);
      }
    } else {
      if (userTypes.includes(value)) {
        newUserTypes = userTypes.filter((stream) => stream !== value);
        setUserTypes(newUserTypes);
      }
    }
  };

  const showUserTypes = () => {
    let userTypeCheckboxes = [];
    roles?.map((role) => {
      userTypeCheckboxes.push(
        <CheckBox
          label={toTitleCase(role.name)}
          checked={userTypes.includes(role.name)}
          onChange={(e) => changeUserTypeHandler(e, role.name)}
        />
      );
    });
    return userTypeCheckboxes;
  };

  return (
    <div className="FilterMainCont">
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className="AccTitle">User type</Typography>
          {userTypes?.length > 0 && (
            <Typography className="FilterCount">{userTypes?.length}</Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          {roles?.length > 0 && showUserTypes()}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Filter;
