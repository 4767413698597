import React, { useEffect, useState } from "react";

import InnerPageLayout from '../../../components/InnerPageLayout/InnerPageLayout';

import { RiFileInfoFill } from "react-icons/ri";
import { RiGroupLine } from "react-icons/ri";
import { GrGroup } from "react-icons/gr";
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import Item from './Item';
import StoreIcon from '@material-ui/icons/Store';
import { useParams } from "react-router-dom";


const OrderDetails = () => {

  let {order_id} = useParams()
  
 
  const menuOptions = [
    { label: 'label 1' },
    { label: 'label 2' },
    { label: 'label 3' },
    { label: 'label 4' },

  ];


  const breadcrumbsData = [

    {
      label: "Orders",
      link: "/",
      icon: <StoreIcon />,
      //handleClick={handleClick}
    },

    {
      label: order_id,
    },

  ];





  const sideBarTabLabel = [
    // {
    //   icon: <RiFileInfoFill />,
    //   label: "Order Status",
    // },
    {
      icon: <RiGroupLine />,
      label: "Items",
    },
    // {
    //   icon: <GrGroup />,
    //   label: "Payment",
    // },
    // {
    //   icon: <CampaignOutlinedIcon />,
    //   label: "Communications",
    // },

  ]

  const sideBarTabBody = [
    // {
    //   id: 0,
    //   panel: "Order Status",
    // },
    {
      id: 0,
      panel: <Item />,
    },
    // {
    //   id: 2,
    //   panel: "",
    // },

  ]



  return (
    <>
      <InnerPageLayout
        BackArrow={true}
        BackArrowlink="/"
        //BackArrowOnlcikc={BackArrowOnlcikc}
        CloseArrow={true}

        BreadcrumbsStatus={true}
        breadcrumbsData={breadcrumbsData}

        SideBarStatus={true}
        sideBarTabLabel={sideBarTabLabel}
        sideBarTabBody={sideBarTabBody}

      />
    </>

  );
};


export default OrderDetails;



