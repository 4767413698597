import React, { useEffect, useState } from "react";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Buttons from "../Form/Button/Button";
import HorizontalTab from "../HorizontalTab/HorizontalTab";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Button from "@mui/material/Button";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

import Datefield from "../Form/Datefield/Datefield";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Search from "./Search";
import "../../components/HorizontalTab/HorizontalTab.css";
import "./TableSubHeader.css";
import "./Search.css";

const TableSubHeader = (props) => {
  const {
    tabLabel,
    tabPanel,
    TabValue,
    TabHandleChange,
    SearchPlaceholder,
    SearchValue,
    SearchOnChange,
    SearchOnclick,
    SearchOnMouseDown,
    FilterOnClick,
    ExportOnClick,
    dateFilter,
    handleDateFilterChange,
    TableTitle,
    TableTitleSmall,
  } = props;

  // const [value, setValue] = React.useState(0);
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  // const tabLabel = [
  //   {
  //     label: "Invited Users",
  //   },
  //   {
  //     label: "Invite New Users",
  //   },

  // ]

  // const tabPanel = [
  //   {
  //     id: 0,
  //     panel: "1",
  //   },
  //   {
  //     id: 1,
  //     panel: "2",
  //   },
  // ]

  // <TableSubHeader
  //   DateField={true}
  //   Tabs={true}
  //   tabLabel={tabLabel}
  //   tabPanel={tabPanel}
  //   TabValue={value}
  //   TabHandleChange={handleChange}
  //   Filter={true}
  //   //FilterOnClick={FilterOnClick}

  //   //== Search Code == //
  //   Search={true}
  //   SearchPlaceholder="Search..."
  //   SearchValue={searchTerm}
  //   SearchOnChange={handleSearchText}
  //   //SearchOnclick={SearchOnclick}
  //   //SearchOnMouseDown={SearchOnMouseDown}
  //   handleSearch={handleSearch}
  //   Border={true}
  // />

  return (
    <>
      <div className="TableSubHeader">
        <div className="TableHeaderSection">
          <h1>{TableTitle}</h1>
          <h2>{TableTitleSmall}</h2>
        </div>

        {props.Tabs && (
          <div className="HorizontalTab">
            <div className="HorizontalTabLabel">
              <Tabs
                value={TabValue}
                onChange={TabHandleChange}
                aria-label="simple tabs example"
              >
                {tabLabel.map((item, i) => (
                  <Tab label={item.label} icon={item.icon} {...a11yProps(0)} />
                ))}
              </Tabs>
            </div>
          </div>
        )}

        {props.DateField && (
          <div className="TableDateField">
            <div className="DateChangeArrowArrowContainer">
              <div className="DateChangeArrow">
                <Button
                  className="ArrowLeft arrow"
                  onClick={props.goToPreviousDate}
                >
                  <ArrowBackIosNewRoundedIcon />
                </Button>
              </div>
              <div className="DateChangeArrow">
                <Button
                  className="ArrowRight arrow"
                  onClick={props.goToNextDate}
                >
                  <ArrowForwardIosRoundedIcon />
                </Button>
              </div>
            </div>
            <Datefield
              value={dateFilter}
              handleChange={handleDateFilterChange}
              dateFormat={props?.filterDateFormat}
            />
          </div>
        )}

        <span></span>
        <div className="Tb_hr_R_Section">
          {props.Search && (
            <div className="Tb_hr_Search">
              <Search
                SearchPlaceholder={SearchPlaceholder}
                SearchValue={SearchValue}
                SearchOnChange={SearchOnChange}
                SearchOnclick={SearchOnclick}
                SearchOnMouseDown={SearchOnMouseDown}
                setResetSearchTerm={props.setResetSearchTerm}
                resetsearchTerm={props.resetsearchTerm}
                setSearchTerm={props.setSearchTerm}
                handleSearch={props.handleSearch}
              />
            </div>
          )}

          {props.Export && (
            <div className="Tbl_Actions">
              <Button onClick={ExportOnClick}>
                <FileUploadOutlinedIcon /> Export
              </Button>
            </div>
          )}

          {props.Filter && (
            // <div className="FilterButtonDrawer">
            //   <Button
            //     onClick={FilterOnClick}
            //   >
            //     <FilterAltIcon /> Filter
            //   </Button>
            // </div>
            <div className="FilterButtonDrawer">
              <Buttons
                icon={<FilterAltIcon />}
                label="Filter"
                buttonColor="#fff"
                border="#C6C6D0 0px solid"
                color="#5C677D"
                height="20px"
                fontSize="14px"
                filterCountStatus={props.filterCountStatus}
                filterCountNumber={props.filterCountNumber}
                onClick={FilterOnClick}
              />
            </div>
          )}
        </div>
      </div>

      {props.Border && <div className="BorderBottom"></div>}

      {props.Tabs && (
        <div className="HorizontalTaleTabPanel">
          {tabPanel.map((item, i) => (
            <CustomTabPanel value={TabValue} index={item.id}>
              {item.panel}
            </CustomTabPanel>
          ))}
        </div>
      )}
    </>
  );
};

export default TableSubHeader;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
